<template>
  <div>loading...</div>
</template>

<script>
export default {
  name: "ShopifyConnection",

  data() {
    return {

    }
  },

  mounted() {

    this.$store.dispatch('setShopifyConnection', this.$route.query).then(response => {
      switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
        case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
          let data = this.getRespData(response)
          if(data?.redirect_url) {
            location.href = data.redirect_url
          }
          break
        }
          /**
           * Validation Error
           */
        case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
          let errors = response.response.data.errors;
          this.notifyErrorHelper(errors)
          break
        }
          /**
           * Undefined Error
           */
        default: {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      }
    })
  },

  methods: {

  },

}
</script>

<style scoped>

</style>